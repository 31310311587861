<template>
  <div>
    <el-dialog
      title="客资编辑"
      width="55%"
      :visible.sync="state"
      :modal-append-to-body="false"
      center
      @close="close"
    >
      <el-form label-width="100px" :model="form" :rules="rules" ref="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="客户名" prop="name">
              <el-input v-model="form.name" placeholder="没有请填暂无" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="城市" prop="city">
              <el-input v-model="form.city" placeholder="请输入客人所在城市" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="对接日期">
              <el-date-picker type="date" placeholder="对接日期"
                              v-model="form.createDate"
                              style="width: 100%;"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="手机号">
              <el-input v-model="form.phone" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="微信号">
              <el-input v-model="form.weChat" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="客资来源" prop="source">
              <el-cascader
                  :props="{ checkStrictly: true,
                            expandTrigger: 'hover'}"
                  multiple="false"
                  :show-all-levels="false"
                  style="width: 100%;"
                  :options="sourceArray"
                  v-model="form.sourceId"
                  ref="source"
                  @change="cascaderClsoe"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="婚期">
              <el-input v-model="form.weddingDay" placeholder="请输入婚期"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="婚礼地点">
              <el-input v-model="form.weddingVenue" placeholder="请输入婚礼地点"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="客服" prop="serviceId">
              <el-select placeholder=请选择 v-model="form.serviceId" style="width: 100%;" ref="service">
                <el-option
                    v-for="item in serviceArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21">
            <el-form-item label="备注">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 4}"
                  placeholder="请输入内容"
                  v-model="form.remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cusUpdateSubmit">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "cus-update",
  created() {
    this.pageInit();
  },
  props: {
    cus:{
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    cusUpdateState: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      state: this.cusUpdateState,
      form: {...this.cus},
      sourceArray: [],
      serviceArray: [],
      rules: {
        name: [{required: true, message: '请输入客户名', trigger: 'blur'}],
        city: [{required: true, message: '请输入用户所在城市', trigger: 'blur'}],
        source: [{required: true, message: '请选择客资来源', trigger: 'change'}],
        serviceId: [{required: true, message: '请选择客服', trigger: 'change'}],
      },
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.querySourceIds(this.$selectUtils.cascader).then(response => {
        this.sourceArray = JSON.parse(JSON.parse(response.data.data))
      })
      this.$selectUtils.queryServiceIds().then(response => {
        this.serviceArray = JSON.parse(response.data.data);
      })
    },
    cusUpdateSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$alert("客资名称：" + this.form.name
              + "<br>客资来源："+ this.$refs.source.getCheckedNodes()[0].label
              + "<br>客服："+ this.$refs.service.selectedLabel
              + "<br>确认添加吗？",
              "编辑客资",
              {
                dangerouslyUseHTMLString: true,
                type: "success",
                center: true,
                showCancelButton: true
          }).then(() => {
            this.form.grade=null;
            this.form.source = typeof this.form.sourceId === 'number' ? this.form.sourceId : this.form.sourceId[0]
            this.form.sourceId = null
            this.form.service = this.form.serviceId
            this.form.state = null
            this.$axios({
              method:"PUT",
              url: "/customer/updateCustomer",
              params: this.form
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '客资编辑成功': response.data.msg,
                type: flag ? 'success':'error',
                duration: 1000,
              });
              this.close()
            })
          }).catch(() => {})
        }
      })
    },
    close() {
      this.$emit('close')
    },
    //级联选择关闭
    cascaderClsoe() {
      this.$refs.source.dropDownVisible = false;
    }
  }
}
</script>

<style scoped>

</style>